import React, { FC } from 'react';
import {
  Button,
  ButtonPriority as PRIORITY,
  ThreeDotsLoader,
} from 'wix-ui-tpa';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { DataHook } from '../../../../../types';
import {
  useBlogPosts,
  useBlogPostsHandlers,
} from '../../../../../contexts/widgets';
import Post from './Post';
import { classes, st } from './PostList.st.css';

export const PostList: FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { posts, paging, arePostsLoading } = useBlogPosts();
  const { fetchPosts } = useBlogPostsHandlers();
  const shouldShowLoadMoreButton = paging.total > paging.count;

  return (
    <div className={classes.root}>
      <div className={classes.posts}>
        {posts.map((post) => (
          <Post key={post.id} {...post} />
        ))}
      </div>
      {shouldShowLoadMoreButton && (
        <div className={classes.loadMoreButtonContainer}>
          {arePostsLoading ? (
            <ThreeDotsLoader
              data-hook={DataHook.PostsLoader}
              className={classes.loader}
            />
          ) : (
            <Button
              type="button"
              data-hook={DataHook.LoadMorePostsButton}
              className={st(classes.loadMoreButton, { mobile: isMobile })}
              priority={PRIORITY.secondary}
              upgrade={true}
              onClick={() => fetchPosts()}
            >
              {t('recent-posts.load-more')}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
